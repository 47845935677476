import React from 'react'
import {FiInstagram  , FiFacebook} from 'react-icons/fi'
import {FaTwitter} from 'react-icons/fa'
import {FaFacebookSquare} from 'react-icons/fa'
import {ImLinkedin} from 'react-icons/im'
function OurTeam() {
    return (
        <>
        <section id="team" class="team">

<div class="container" data-aos="fade-up">

    <header class="section-title">
      <h2>Team</h2>
      <p>Our management  </p>
    </header>

  <div class="row gy-4">  
      <div className='col-lg-4 col-md-6 d-flex align-items-stretch'></div>
     
    {/* <div className='col-lg-3 col-md-6 d-flex align-items-stretch'></div> */}
    
    <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
      <div class="member">
        <div class="member-img">
          <img src="../img/Team/Meet-patel-1.jpg" class="img-fluid" alt="" />
          <div class="social">
            <a href=""><FiInstagram></FiInstagram></a>
            <a href=""><FaTwitter></FaTwitter></a>
            <a href=""><FaFacebookSquare></FaFacebookSquare></a>
            <a href=""><ImLinkedin></ImLinkedin></a>
          </div>
        </div>
        <div class="member-info">
          <h4>Meet Patel</h4>
          <span>Founder & CEO</span>
          <p></p>
        </div>
      </div>
    </div>
    
    <div className='col-lg-4 col-md-6 d-flex align-items-stretch'></div>
    {/* <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
      <div class="member">
        <div class="member-img">
          <img src="../img/Team/team-3.jpg" class="img-fluid" alt="" />
          <div class="social">
          <a href=""><FiInstagram></FiInstagram></a>
            <a href=""><FaTwitter></FaTwitter></a>
            <a href=""><FaFacebookSquare></FaFacebookSquare></a>
            <a href=""><ImLinkedin></ImLinkedin></a>
          </div>
        </div>
        <div class="member-info">
        <h4>Meet Patel</h4>
          <span>Founder & CEO</span>
          <p>Quo esse repellendus quia id. Est eum et accusantium pariatur fugit nihil minima suscipit corporis. Voluptate sed quas reiciendis animi neque sapiente.</p>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
      <div class="member">
        <div class="member-img">
          <img src="../img/Team/team-3.jpg" class="img-fluid" alt="" />
          <div class="social">
          <a href=""><FiInstagram></FiInstagram></a>
            <a href=""><FaTwitter></FaTwitter></a>
            <a href=""><FaFacebookSquare></FaFacebookSquare></a>
            <a href=""><ImLinkedin></ImLinkedin></a>
          </div>
        </div>
        <div class="member-info">
        <h4>Meet Patel</h4>
          <span>Founder & CEO</span>
          <p>Vero omnis enim consequatur. Voluptas consectetur unde qui molestiae deserunt. Voluptates enim aut architecto porro aspernatur molestiae modi.</p>
        </div>
      </div>
    </div>

    <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="400">
      <div class="member">
        <div class="member-img">
          <img src="../img/Team/team-3.jpg" class="img-fluid" alt="" />
          <div class="social">
          <a href=""><FiInstagram></FiInstagram></a>
            <a href=""><FaTwitter></FaTwitter></a>
            <a href=""><FaFacebookSquare></FaFacebookSquare></a>
            <a href=""><ImLinkedin></ImLinkedin></a>
          </div>
        </div>
        <div class="member-info">
        <h4>Meet Patel</h4>
          <span>Founder & CEO</span>
          <p>Rerum voluptate non adipisci animi distinctio et deserunt amet voluptas. Quia aut aliquid doloremque ut possimus ipsum officia.</p>
        </div>
      </div>
    </div> */}

  </div>

</div>

</section>

            
        </>
    )
}

export default OurTeam
