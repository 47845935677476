import React from 'react'
import {GrFacebookOption ,GrInstagram , GrLinkedinOption , GrTwitter} from 'react-icons/gr'

function Navbar() {
    return (
        <>
<nav class="navbar fixed-top navbar-expand-lg navbar-light bg-white sticky mb-2">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
    <img src="/Karmayogi.png" width={"130px"} className=""   alt="..." />
        </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
      <li className="nav-item active">
                        <a className="nav-link" href="/">Home</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/about">About Us</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/products">Products</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/gallery">Gallery</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/contact">Contact US</a>
                    </li>
                    
      </ul>

      <ul class="header navbar-nav ms-auto mb-2 mb-lg-0 text-center">
                <div class="social-links">
                <a href="#" class="facebook"><i class="bx bxl-facebook"><GrFacebookOption></GrFacebookOption></i></a>
                <a href="https://www.instagram.com/ko_eco_product/" target={"_blank"} class="instagram"><i class="bx bxl-instagram"><GrInstagram></GrInstagram></i></a>
                <a href="#" class="linkedin"><i class="bx bxl-linkedin"><GrLinkedinOption></GrLinkedinOption></i></a>
                <a href="#" class="linkedin"><i class="bx bxl-linkedin"><GrTwitter></GrTwitter></i></a>
                </div>
      </ul>
      
    </div>
  </div>
</nav>
          {/* <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a className="navbar-brand" href="#">Karmayogi Organics</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">

                    <li className="nav-item active">
                        <a className="nav-link" href="/">Home</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/about">About Us</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/products">Products</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/gallery">Gallery</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/contact">Contact US</a>
                    </li>
                    
                    </ul>
                </div>
</nav>   */}
        </>
    )
}

export default Navbar
