import React from 'react'

function Gallery() {
    return (
        <>
            <section id="gallery" class="gallery">
      <div class="container">

      <header class="section-title">
        <h2>Gallery</h2>
        <p>Photo Gallery</p>
      </header>
       
        <div class="row no-gutters">

          <div class="col-lg-3 col-md-4 my-2">
            <div class="gallery-item">
              <a href="#" class="galleery-lightbox" data-gallery="gallery-item">
                <img src="../img/gallery/gallery-1.jpg" alt="img" class="img-fluid" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 my-2">
            <div class="gallery-item">
              <a href="#" class="galleery-lightbox" data-gallery="gallery-item">
                <img src="../img/gallery/gallery-2.jpg" alt="" class="img-fluid" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 my-2">
            <div class="gallery-item">
              <a href="#" class="galleery-lightbox" data-gallery="gallery-item">
                <img src="../img/gallery/gallery-3.jpg" alt="" class="img-fluid" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 my-2">
            <div class="gallery-item">
              <a href="#" class="galleery-lightbox" data-gallery="gallery-item">
                <img src="../img/gallery/gallery-4.jpg" alt="" class="img-fluid" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 my-2">
            <div class="gallery-item">
              <a href="#" class="galleery-lightbox" data-gallery="gallery-item">
                <img src="../img/gallery/gallery-5.jpg" alt="" class="img-fluid" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 my-2">
            <div class="gallery-item">
              <a href="#" class="galleery-lightbox" data-gallery="gallery-item">
                <img src="../img/gallery/gallery-6.jpg" alt="" class="img-fluid" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 my-2">
            <div class="gallery-item">
              <a href="" class="galleery-lightbox" data-gallery="gallery-item">
                <img src="../img/gallery/gallery-7.jpg" alt="" class="img-fluid" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 my-2">
            <div class="gallery-item">
              <a href="#" class="galleery-lightbox" data-gallery="gallery-item">
                <img src="../img/gallery/gallery-8.jpg" alt="" class="img-fluid" />
              </a>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 my-2">
            <div class="gallery-item">
              <a href="#" class="galleery-lightbox" data-gallery="gallery-item">
                <img src="../img/gallery/gallery-9.jpg" alt="" class="img-fluid" />
              </a>
            </div>
          </div>
          {/* <div class="col-lg-3 col-md-4">
            <div class="gallery-item">
              <a href="#" class="galleery-lightbox" data-gallery="gallery-item">
                <img src="../img/gallery/gallery-10.jpg" alt="" class="img-fluid" />
              </a>
            </div>
          </div>
          <div class="col-lg-3 col-md-4">
            <div class="gallery-item">
              <a href="#" class="galleery-lightbox" data-gallery="gallery-item">
                <img src="../img/gallery/gallery-11.jpg" alt="" class="img-fluid" />
              </a>
            </div>
          </div> */}
          <div class="col-lg-3 col-md-4 my-2">
            <div class="gallery-item">
              <a href="#" class="galleery-lightbox" data-gallery="gallery-item">
                <img src="../img/gallery/gallery-12.jpg" alt="" class="img-fluid" />
              </a>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 my-2">
            <div class="gallery-item">
              <a href="#" class="galleery-lightbox" data-gallery="gallery-item">
                <img src="../img/gallery/gallery-13.jpg" alt="" class="img-fluid" />
              </a>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 my-2">
            <div class="gallery-item">
              <a href="#" class="galleery-lightbox" data-gallery="gallery-item">
                <img src="../img/gallery/gallery-14.jpg" alt="" class="img-fluid" />
              </a>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 my-2">
            <div class="gallery-item">
              <a href="#" class="galleery-lightbox" data-gallery="gallery-item">
                <img src="../img/gallery/gallery-15.jpg" alt="" class="img-fluid" />
              </a>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 my-2">
            <div class="gallery-item">
              <a href="#" class="galleery-lightbox" data-gallery="gallery-item">
                <img src="../img/gallery/gallery-16.jpg" alt="" class="img-fluid" />
              </a>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 my-2">
            <div class="gallery-item">
              <a href="#" class="galleery-lightbox" data-gallery="gallery-item">
                <img src="../img/gallery/gallery-17.jpg" alt="" class="img-fluid" />
              </a>
            </div>
          </div>


        </div>

      </div>
    </section>

        </>
    )
}

export default Gallery
