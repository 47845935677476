import React from 'react'

function OurClients() {
    return (
        <>
            <section id="clients" class="clients">
                
      <div class="container">

      <header class="section-title">
                <h2>Our Clients</h2>
                <p>We provide Our Services to These Companies  </p>
      </header>

        <div class="row no-gutters clients-wrap clearfix wow fadeInUp">

          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src="../img/clients/client-3.png" class="img-fluid" alt="" />
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src="../img/clients/client-3.png" class="img-fluid" alt="" />
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src="../img/clients/client-3.png" class="img-fluid" alt="" />
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src="../img/clients/client-3.png" class="img-fluid" alt="" />
            </div>
          </div>

          {/* <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src="../img/clients/client-3.png" class="img-fluid" alt="" />
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src="../img/clients/client-3.png" class="img-fluid" alt="" />
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src="../img/clients/client-3.png" class="img-fluid" alt="" />
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-xs-6">
            <div class="client-logo">
              <img src="../img/clients/client-3.png" class="img-fluid" alt="" />
            </div>
          </div> */}

        </div>

      </div>
    </section>

        </>
    )
}

export default OurClients
