import React from 'react'
import Products from '../components/Products'
function ProductPage() {
    return (
        <>
            <Products></Products>
        </>
    )
}

export default ProductPage
