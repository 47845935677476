import React from 'react'

function Products() {
    return (
        <>
            <section className="products">
                <div className="container">

                    <div className="section-title">
                        <h2>Products</h2>
                        <p>Check Out Our Products</p>
                    </div>

                    <div className="row ">
                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch justify-content-center" >
                            <div className="product">
                                <div className="img"><img src="../img/Products/Product-1-1.jpg" height={"100%"} alt="img"></img></div>
                                <h4><a href="#">Chocolate Spoon</a></h4>
                                {/* <p>This is the chocolate spoon which is best for ice-cream</p> */}
                            </div>
                            
                        </div>
                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch justify-content-center" >
                            <div className="product">
                                <div className="img"><img src="../img/Products/Product-2-2.jpg" alt="img"></img></div>
                                <h4><a href="#">Mango Spoon</a></h4>
                                {/* <p></p> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch justify-content-center" >
                            <div className="product">
                                <div className="img"><img src="../img/Products/Product-3-3.jpg" alt="img"></img></div>
                                <h4><a href="#">Mint Spoon</a></h4>
                                {/* <p>This is the chocolate spoon which is best for ice-cream</p> */}
                            </div>
                        </div>
                       
                    </div> <br /><br />
                    <div className="row my-6">
                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch justify-content-center" >
                            <div className="product">
                                <div className="img"><img src="../img/Products/Product-4-4.jpg" alt="img"></img></div>
                                <h4><a href="#">Special Spice Spoon</a></h4>
                                {/* <p>This is the chocolate spoon which is best for ice-cream</p> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch justify-content-center" >
                            <div className="product">
                                <div className="img"><img src="../img/Products/Product-5-5.jpg" alt="img"></img></div>
                                <h4><a href="#">Vanilla Spoon</a></h4>
                                {/* <p>This is the chocolate spoon which is best for ice-cream</p> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch" >
                            <div className="product">
                                <div className="img"><img src="../img/Products/Product-1-1.jpg" alt="img"></img></div>
                                <h4><a href="#">Spoon</a></h4>
                                <p>This is the chocolate spoon which is best for ice-cream</p>
                            </div>
                        </div>
                       
                    </div>

                </div>
            </section>  
        </>
    )
}

export default Products
