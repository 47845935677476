import './App.scss';
import {BrowserRouter as Router , Route , Switch  } from 'react-router-dom'
import HomePage from './pages/HomePage'; 
import ContactPage from './pages/ContactPage';
import AboutPage from './pages/AboutPage';
import Navbar from './components/Navbar';
import ProductPage from './pages/ProductPage'
import Gallery from './pages/Gallery'
import Footer from './components/Footer';

function App() {
  return (
    <>
    <Router>
      <Navbar></Navbar>
      <Switch>
        <Route path="/" exact component={HomePage}></Route>
        <Route path="/about" component={AboutPage}></Route>
        <Route path="/products" component={ProductPage}></Route>
        <Route path="/gallery" component={Gallery}></Route>
        <Route path="/contact" component={ContactPage}></Route>
      </Switch>
      <Footer></Footer>
    </Router>
    </>
  );
}

export default App;
