import React from 'react'

function WhyUs() {
    return (
        <>
            <section id="why-us" class="why-us">
      <div class="container">

        <div class="section-title">
          <h2>Why choose <span>us</span></h2>
          <p>We make products that make the planet happy.</p>
        </div>

        <div class="row">

          <div class="col-lg-4">
            <div class="box">
              <span>01</span>
              <h4>Our client's Success is our Success</h4>
              <p>To deliver genuine organic foods and products to our consumers.
                  Which helps our client to go forward One step ahead .</p>
            </div>
          </div>

          <div class="col-lg-4 mt-4 mt-lg-0">
            <div class="box">
              <span>02</span>
              <h4>100% Eco-friendly products</h4>
              <p>To support natural, sustainable, organic, agriculture practices that
                  serve and protect Mother Nature.</p>
            </div>
          </div>

          <div class="col-lg-4 mt-4 mt-lg-0">
            <div class="box">
              <span>03</span>
              <h4>Best Quality Products</h4>
              <p>To introduce a unique and successful business model that is committed
                  to service and integrity, and benefits all.</p>
            </div>
          </div>

        </div>

      </div>
    </section>

        </>
    )
}

export default WhyUs
