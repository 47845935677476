import React from 'react'

function License() {
    return (
        <>
            <section id="services" class="services">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>License</h2>
          <p>We are safe.</p>
        </div>

        <div class="row">

          <div class="col-md-6 col-lg-3 d-flex justify-content-center align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div class="icon"><img src="../img/Fssai.jpeg" /></div>
            </div>
          </div>

          {/* <div class="col-md-6 col-lg-3 d-flex justify-content-center align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div class="icon"><img src="../img/1logo.png" /></div>
              
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex justify-content-center align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
              <div class="icon"><img src="../img/1logo.png" /></div>
            </div>
          </div> */}

          {/* <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"> */}
            {/* <div class="icon-box" data-aos="fade-up" data-aos-delay="400"> */}
              {/* <div class="icon"><img src="../img/1logo.png" /></div> */}
              
            {/* </div> */}
          {/* </div> */}

        </div>

      </div>
    </section>

        </>
    )
}

export default License
