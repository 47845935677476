import React from 'react'
import License from '../components/License'
import WhyUs from '../components/WhyUs'
import Crousel from '../components/Crousel'
import OurClients from '../components/OurClients'
import Testimonials from '../components/Testimonials'

function HomePage() {
    return (
        <>
               <section id="about" class="about">
      

            <Crousel></Crousel>
            <WhyUs></WhyUs>
            {/* <OurClients></OurClients> */}
            {/* <Testimonials></Testimonials> */}
            <License></License>
            
            </section>
            
        </>
    )
}

export default HomePage
