import React from 'react'
import {FaChevronRight} from 'react-icons/fa'
import {GrInstagram , GrFacebookOption , GrLinkedinOption , GrTwitter} from 'react-icons/gr'

function Footer() {
    return (
        <>
            <footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-4 col-md-6">
            <div class="footer-info">
              <h3>Karmayogi Organics</h3>
              <p class="pb-3"><em>We make products that make the planet happy.</em></p>
              <p>
                Karmayogi Organics <br />
                360370 - Jetpur , Gujarat<br /><br />
                <strong>Phone:</strong> +91 7265064418<br></br>
                <strong>Email:</strong> Karmayogiorganics@gmail.com<br></br>
              </p>
              <div class="social-links mt-3">
                <a href="#" class="facebook"><i class="bx bxl-facebook"><GrFacebookOption></GrFacebookOption></i></a>
                <a href="https://www.instagram.com/ko_eco_product/" target={"_blank"} class="instagram"><i class="bx bxl-instagram"><GrInstagram></GrInstagram></i></a>
                <a href="#" class="linkedin"><i class="bx bxl-linkedin"><GrLinkedinOption></GrLinkedinOption></i></a>
                <a href="#" class="linkedin"><i class="bx bxl-linkedin"><GrTwitter></GrTwitter></i></a>
             
              </div>
            </div>
          </div>

          <div class="col-lg-2 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i class="bx bx-chevron-right"><FaChevronRight></FaChevronRight></i> <a href="/">Home</a></li>
              <li><i class="bx bx-chevron-right"><FaChevronRight></FaChevronRight></i> <a href="/about">About us</a></li>
              <li><i class="bx bx-chevron-right"><FaChevronRight></FaChevronRight></i> <a href="/contact">Contact Us</a></li>
              <li><i class="bx bx-chevron-right"><FaChevronRight></FaChevronRight></i> <a href="/products">Our Products</a></li>
              <li><i class="bx bx-chevron-right"><FaChevronRight></FaChevronRight></i> <a href="#">Policy</a></li>
            </ul>
          </div>

          <div class="col-lg-2 col-md-6 footer-links">
            <h4>Our Products</h4>
            <ul>
              <li><i class="bx bx-chevron-right"><FaChevronRight></FaChevronRight></i> <a href="/products">Chocolate Spoon</a></li>
              <li><i class="bx bx-chevron-right"><FaChevronRight></FaChevronRight></i> <a href="/products">Special Spice Spoon</a></li>
              <li><i class="bx bx-chevron-right"><FaChevronRight></FaChevronRight></i> <a href="/products">Mint Spoon</a></li>
              <li><i class="bx bx-chevron-right"><FaChevronRight></FaChevronRight></i> <a href="/products">Mango Spoon</a></li>
              <li><i class="bx bx-chevron-right"><FaChevronRight></FaChevronRight></i> <a href="/products">Vanilla Spoon</a></li>
            </ul>
          </div>

          <div class="col-lg-4 col-md-6 footer-newsletter">
            <img src='made-in-india.png' width={'300px'} className='my-4'></img>
            <h4>Review & Suggestions</h4>
            <p>Please Share your Review and Suggestions obout our products .</p>
            <form action="#">
              <input type="text"  /><input type="submit" value="Send" />
            </form>

          </div>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; Copyright <span style={{fontWeight:"500"}}>Karmayogi Organics</span>. All Rights Reserved
      </div>
      <div class="credits">
        Designed by <a href="https://karmaSoftech.com/">Karma Softech</a>
      </div>
    </div>
  </footer>

        </>
    )
}

export default Footer
