import React from 'react'
import OurTeam from '../components/OurTeam'
import {GrEmoji} from 'react-icons/gr'
import {IoIosJournal} from 'react-icons/io'
import {FaHeadset , FaPeopleCarry , FaCheckDouble , FaChevronDown , FaChevronUp} from 'react-icons/fa'


function AboutPage() {
    return (
        <>
            <section id="about" class="about">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>About Us</h2>
          <p>We make products that make the planet happy.</p>
        </div>

        <div class="row content">
          <div class="col-lg-6">
            <p>
            We have zero tolerance for plastic. Single-use plastic is probably one of the worst things to happen to our planet, and we don’t allow it in our world.
            </p>
            <ul>
              <li><i class="ri-check-double-line"><FaCheckDouble></FaCheckDouble></i> A peaceful and sustainable world based on societies living in harmony with nature.</li>
              <li><i class="ri-check-double-line"><FaCheckDouble></FaCheckDouble></i> Each generation enjoying an environment that’s getting better; a safer climate, abundant nature, healthy air, water and food.</li>
              <li><i class="ri-check-double-line"><FaCheckDouble></FaCheckDouble></i> A growing and diverse network of people coming together to transform our environment into one which is flourishing, sustainable, and socially just.</li>
            </ul>
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0">
            <p>
            To be an inspiring, trustworthy and innovative global leader in providing genuine organic products and solutions for Healthy Conscious Living. To be a vehicle of consciousness in the global market by creating a holistic, sustainable business model that inspires, promotes and supports true wellness.
            </p>
            <a href="../products" class="btn-learn-more">See Products</a>
          </div>
        </div>

      </div>
    </section>

    <section id="counts" class="counts">
      <div class="container" data-aos="fade-up">

        <div class="row no-gutters">

          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
            <div class="count-box">
              <i class="bi bi-emoji-smile"><GrEmoji></GrEmoji></i>
              <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1" class="purecounter">100+</span>
              <p><strong>Happy Clients</strong></p>
              <a href="../products" style={{textDecoration:"none"}}>See Our Products &raquo;</a>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
            <div class="count-box">
              <i class="bi bi-journal-richtext"><IoIosJournal></IoIosJournal></i>
              <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" class="purecounter">50+</span>
              <p><strong>Projects</strong></p>
              <a href="../products" style={{textDecoration:"none"}}>See Our Products &raquo;</a>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
            <div class="count-box">
              <i class="bi bi-headset"><FaHeadset></FaHeadset></i>
              <span data-purecounter-start="0" data-purecounter-end="1463" data-purecounter-duration="1" class="purecounter">18x7</span>
              <p><strong>Hours Of Support</strong></p>
              <a href="../products" style={{textDecoration:"none"}}>See Our Products &raquo;</a>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
            <div class="count-box">
              <i class="bi bi-people"><FaPeopleCarry></FaPeopleCarry></i>
              <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1" class="purecounter">12</span>
              <p><strong>Hard Workers</strong></p>
              <a href="../products" style={{textDecoration:"none"}}>See Our Products &raquo;</a>
            </div>
          </div>

        </div>

      </div>
    </section>

    
    <section id="why-us" class="why-us section-bg">
      <div class="container-fluid" data-aos="fade-up">

        <div class="row">
        
          <div class="col-lg-5 align-items-stretch video-box" data-aos="zoom-in" data-aos-delay="100">
            <a href="https://youtube.com/channel/UCK_tVnkjhkBmM0xTDFIlAGA" class="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a>
          </div>

          <div class="col-lg-7 d-flex flex-column justify-content-center align-items-stretch">

            <div class="content">
              <h3>We make products that make the planet happy. <strong> - Karmayogi Organics</strong></h3>
              <p>
              We have zero tolerance for plastic. Single-use plastic is probably one of the worst things to happen to our planet, and we don’t allow it in our world.
              </p>
            </div>

            <div class="accordion-list">
              <ul>
                <li>
                  <a data-bs-toggle="collapse" class="collapse" data-bs-target="#accordion-list-1"><span>01</span> Our Mission<i class="bx bx-chevron-down icon-show"><FaChevronDown></FaChevronDown></i><i class="bx bx-chevron-up icon-close"><FaChevronUp></FaChevronUp></i></a>
                  <div id="accordion-list-1" class="collapse show" data-bs-parent=".accordion-list">
                    <p>
                    To be a trustworthy and innovative global leader in providing genuine organic True Wellness products and solutions for conscious, healthy living.
                    </p>
                  </div>
                </li>

                <li>
                  <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" class="collapsed"><span>02</span> Our Vision <i class="bx bx-chevron-down icon-show"><FaChevronDown></FaChevronDown></i><i class="bx bx-chevron-up icon-close"><FaChevronUp></FaChevronUp></i></a>
                  <div id="accordion-list-2" class="collapse" data-bs-parent=".accordion-list">
                    <p>
                    To be a Vehicle of Consciousness in the global market by creating an holistic, sustainable business modality, which inspires, promotes and supports True Wellness and respect for all Beings and for Mother Nature.
                    </p>
                  </div>
                </li>

                <li>
                  <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" class="collapsed"><span>03</span> Our Commitment<i class="bx bx-chevron-down icon-show"><FaChevronDown></FaChevronDown></i><i class="bx bx-chevron-up icon-close"><FaChevronUp></FaChevronUp></i></a>
                  <div id="accordion-list-3" class="collapse" data-bs-parent=".accordion-list">
                    <p>
                   1)  To introduce a unique and successful business model that is committed to service and integrity, and benefits all. <br />
                   2)  To support natural, sustainable, organic, agriculture practices that serve and protect Mother Nature. <br />
                   3)  To deliver genuine organic foods and products to our consumers.
                    </p>
                  </div>
                </li>

              </ul>
            </div>

          </div>

        </div>

      </div>
    </section>

            <OurTeam></OurTeam>

        </>
    )
}

export default AboutPage
